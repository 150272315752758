import styled from "styled-components";
import { Navbar, Form } from "react-bootstrap";
import { Step } from "semantic-ui-react";
import { Link } from "react-router-dom";

export const StepJedha = styled(Step)`
  color: #1f4352 !important;

  &.active .icon {
    color: #1f4352 !important;
  }
  &.active .title {
    color: #00dbd0 !important;
  }

  .icon {
    color: #1f4352 !important;
  }
`;

export const LinkJedha = styled(Link)`
  color: #1f4352 !important;

  &.active .icon {
    color: #1f4352 !important;
  }
  &.active .title {
    color: #00dbd0 !important;
  }

  .icon {
    color: #1f4352 !important;
  }
`;

export const FormJedha = styled(Form)`
  border: none;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
    0 4px 6px 0 rgba(112, 157, 199, 0.15);
  border-radius: 4px;
  background-color: #00dbd0;
`;

export const NavbarJedha = styled(Navbar)`
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
    0 4px 6px 0 rgba(112, 157, 199, 0.15);
`;

export const Fieldset = styled.fieldset`
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
    0 4px 6px 0 rgba(112, 157, 199, 0.15);
  border-radius: 4px;
  border: none;
`;

export const FieldTitle = styled.h2`
  color: #fff;
  font-weight: bold;
  font-size: 1.5rem;
`;

export const Label = styled.label`
  font-weight: bold;
  height: 42px;
  padding: 10px 0px;
  color: #1f4352;
  border-bottom: 1px solid #f0f5fa;
`;

export const Input = styled.input`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  height: 40px;
  border: none;

  color: #32325d;
  background-color: white;

  padding: 10px 12px;

  :focus {
    outline: none;
  }
`;

export const FormSubmit = styled.button`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-top: 1px solid #819efc;
  height: 40px;

  color: white;
  font-weight: bold;
  background-color: #1f4352;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;

  padding: 10px 12px;

  :disabled {
    display: none;
  }
`;

export const SectionTitle = styled.h3`
  font-weight: bold;
  font-size: 1.5rem;
  color: #1f4352;
`;

export const InstallmentPlanTitle = styled.span`
  font-weight: bold;
  color: #1f4352;
`;

export const InstallmentPlanDefinition = styled.p`
  font-size: 10px;
  font-weight: 300;
`;

export const InstallmentPlanExample = styled.p`
  font-size: 10px;
  font-weight: 300;
  font-style: italic;
`;

export const ReferenceSepa = styled.p`
  font-size: 10px;
`;

export const ConfirmationJedha = styled.div`
  border: none;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
    0 4px 6px 0 rgba(112, 157, 199, 0.15);
  border-radius: 4px;
  background-color: #fff;
`;
